<template>
  <v-app-bar app elevation="0" color="pink accent-2" dark>
    <v-layout row align-center>
      <!-- <v-flex shrink>
            <v-img alt="Prepaylink logo" width="40" contain src="@/assets/fulllogo.png" />
          </v-flex>
          <v-spacer></v-spacer> -->
      <v-flex class="pl-5" shrink>
        <div :class="{ 'animate__shakeY': runIt }" class="animate__animated mr-3 font-weight-black">
          {{ getBalance | currency }}
          Balance</div>

        <!-- <v-btn small class="rounded-lg" @click="addFund" color="primary">Add Funds</v-btn> -->
      </v-flex>
      <v-flex shrink>
        <AddFunds :contact="contact" />
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex shrink class="pr-3">
        <Menu />
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import employeeService from "@/modules/Employee/service";
import EventBus from '@/event-bus.js';
import Menu from "@/components/AppBarMenu.vue";
import homeService from '@/modules/Home/service.js'
import AddFunds from '@/modules/Wallet/AddFounds.vue'
import 'animate.css';

export default {
  name: "app-bar",
  data() {
    return {
      runIt: false,
      drawer: false,
      contact: null,
    };
  },
  components: {
    Menu,
    AddFunds,
  },
  created() {
    console.log('getContact', this.getContact)
    EventBus.$on('reload-contact-information', () => {
      this.runIt = true
      setTimeout(() => {
        this.runIt = false
      }, 1000);
      this.loadContactData()
    })
    this.loadContactData()
  },
  beforeDestroy() {
    EventBus.$off('reload-contact-information')
  },
  computed: {
    ...mapGetters('global', ['getContact', 'getBalance'])
  },
  sockets: {
    UPDATEBALANCE(data) {
      console.log('UPDATEBALANCE data', data)
      this.loadContactData()
      // EventBus.$off('reload-activation-list')
    },
  },
  methods: {
    ...mapActions("global", ["setContact", "setBalance"]),
    loadContactData() {
      homeService.Customer.getById(this.getContact.id)
        .then(result => {
          console.log('getContact result', result)
          this.contact = result.data
          this.balance = this.contact.CustomerInfos[0].account
          this.setContact(this.contact);
          this.setBalance(this.balance)
        })
    },
    addFund() {
      this.$toast.open({
        message: 'Contact your sales agent',
        duration: 5000,
        type: 'default',
        dismissible: true,
        position: 'top',
      });
    }
  }
};
</script>
