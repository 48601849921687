<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="black" size="28" v-bind="attrs" v-on="on">
          <img v-if="getImage" :src="getImage" class="image" alt="EmployeeImage" />
          <v-icon small dark v-else>mdi-account</v-icon>
        </v-avatar>


      </template>

      <v-card class="rounded-lg ">
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-avatar>
              <img src="@/assets/profile-user.png" class="image" alt="EmployeeImage" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ getContact.businessName }}</v-list-item-title>
              <v-list-item-subtitle v-if="getContact.metadata && getContact.metadata.dealerCode">
                Dealer Code: <strong>{{getContact.metadata.dealerCode}}</strong>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="primary" icon @click="moveToProfile">
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>


        <v-divider></v-divider>

        <v-list color="transparent">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Need Help Contact</v-list-item-title>
              <v-list-item-subtitle>
                <a href="mailto:help@simcard.support">help@simcard.support</a><br />
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-title>Version: <strong>{{ version }}</strong></v-list-item-title> -->
            <!-- <v-list-item-action-text>saeer</v-list-item-action-text> -->

          </v-list-item>
         
          

        </v-list>

        <v-card-actions>
          
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="logout">
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-dialog v-model="dialog" width="500" persistent transition="dialog-transition">
      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5">
          Setting
          <v-spacer></v-spacer>
          <v-btn text icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <v-text-field reverse type="number" dense hide-details outlined v-model="defaultFee"
            @focus="$event.target.select()" autofocus label="Default Fee">
          </v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="caption">Version: <strong>{{ version }}</strong></div>
          <v-spacer></v-spacer>
          <v-btn class="rounded-lg" :loading="loading" @click="submit" color="primary">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>
// import UserImg from "@/assets/images/user.jpeg";
import { mapGetters, mapActions } from "vuex";
const pjson = require('../../package.json');
import homeService from '@/modules/Home/service.js'

export default {
  name: "app-menu-bar",
  data() {
    return {
      dialog: false,
      loading: false,
      defaultFee: 0,
      menu: false,
    };
  },
  created() {
    console.log('this.getContact.metadata.defaultFee', this.getContact)
    if (
      this.getContact &&
      this.getContact.metadata &&
      this.getContact.metadata.defaultFee
    ) this.defaultFee = this.getContact.metadata.defaultFee;
  },
  watch: {
    dialog(val) {
      console.log('this.getContact', this.getContact)
      if (val) {
        // defaultFee = this.getContact.metadata.defaultFee
        if (
          this.getContact &&
          this.getContact.metadata &&
          this.getContact.metadata.defaultFee
        ) this.defaultFee = this.getContact.metadata.defaultFee;
      }
    }
  },
  methods: {
    ...mapActions("global", ["setContact", "setBalance"]),
    logout() {
      this.$router.push({ name: 'authentication-logout' });
    },
    moveToProfile() {
      console.log("lastEmployee", this.getContact);
      this.dialog = true
    },
    loadContactData() {
      homeService.Customer.getById(this.getContact.id)
        .then(result => {
          console.log('getContact result', result)
          this.contact = result.data
          this.balance = this.contact.CustomerInfos[0].account
          this.setContact(this.contact);
          this.setBalance(this.balance)
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true
      let metadata = this.getContact.metadata
      metadata.defaultFee = this.defaultFee
      console.log('metadata', metadata)
      homeService.Customer.update(this.getContact.id, { metadata })
        .then(result => {
          console.log('resul tis :', result)
          this.$toast.open({
            message: 'Default fee was successfully updated!',
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          this.loadContactData()
          this.loading = false
          this.dialog = false
        })
        .catch(error => {
          this.loading = false
          this.$toast.open({
            message: 'Unable to update default fee!',
            dismissible: true,
            type: 'error',
            position: 'top-right',
          });
        })
    },

  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
    version() {
      return pjson.version;
    },
    getImage() {
      if (this.employee && this.employee.imageURL) {
        return this.employee.imageURL;
      }
    },
  },
};
</script>

<style scoped>
.glassCard {
  background: rgba(255, 255, 255, 0.31) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.8px);
  -webkit-backdrop-filter: blur(8.8px);
  border: 1px solid rgba(255, 255, 255, 0.68) !important;
}
</style>