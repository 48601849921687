<template>
  <div >
    <Appbar />

    <router-view></router-view>
    <!-- <BottomNavigation /> -->
  </div>
</template>

<script>
import authService from "@/modules/Authentication/service.js";
// import employeeService from "@/modules/Employee/service";
import { mapActions } from "vuex";
// import locationService from "@/modules/Location/service.js";
// import BottomNavigation from "@/components/BottomNavigation.vue";
const notificationSound = new Audio(require('../../assets/audio/cellsim_notification_sound.wav'));

import Appbar from "@/components/Appbar";

export default {
  name: "layout",
  data() {
    return {
      // loading: false,
      location: null,
      authenticated: true,
      polling: null,
      AssignedContactId: null,
			webAuthenticatorTimer: null
    };
  },
  components: {
    // BottomNavigation,
    Appbar,
  },
  // computed: {
  //   ...mapGetters("global", ["getContactNumber", "getContact"]),
  // },
  watch: {
    authenticated(val) {
      if (!val) {
        this.$router.push({ name: "authentication-employee-login" });
      }
    },
  },
  sockets: {
    connect() {
      console.log("socket connected Layout CellSims");
      // Make sure to rejoin the channels
      this.joinSocketChannels();
    },
    message(data) {
      console.log("New message.", data);
    },
    settingChange() {
      console.log("setting Change");
    },
    SERIALCREATED(data) {

      if (data.ContactId && data.ContactId == this.AssignedContactId) {
        this.$toast.open({
          message: `${data.serialNumber} was assigned to you`,
          dismissible: true,
          type: 'success',
          position: 'top-right',
        });
        // this.$swal('Sim Assigned', `${data.serialNumber} was assigned to you`, 'success');
        notificationSound.play();
      }
    },
    orderShipped(data) {

      if (data[0].ContactId == this.AssignedContactId) {
        this.$toast.open({
          message: `Order no ${data[0].id}has been shipped\nTracking Number: ${data[0].metadata.shipping.trackingNumber}`,
          dismissible: true,
          type: 'success',
          position: 'top-right',
        });
        // this.$swal('Order Shipped', `Order no ${data[0].id}has been shipped\nTracking Number: ${data[0].metadata.shipping.trackingNumber}`, 'success');
        notificationSound.play();
      };
    },
    markedActivated(data) {

      console.log('markActvate invoked');
      if (data.AssignedContactId == this.AssignedContactId) {
        this.$swal('Activated', `${data.serialNumber} was marked activated by owner`, 'success');
        notificationSound.play();
      }
    },
		async RESETWEBACTIVATIONTOKEN() {
			await localStorage.removeItem(
				`${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`
			);
			this.$router.push({ path: "/webauthenticator" });
			
		}
  },
  async created() {
    this.checkAuth();
    const contact = localStorage.getItem(
      `${process.env.VUE_APP_STORAGE_PREFIX}contact`
    );

    if (contact) {
      // console.log('comtact is ;a', contact)
      this.AssignedContactId = JSON.parse(contact).id
      this.setContact(JSON.parse(contact));
    }
    this.joinSocketChannels();
		this.authenticateWebToken();
  },
	// mounted() {
	// 	this.webAuthenticatorTimer = setInterval(() => {
  //   this.authenticateWebToken()
  // }, 1000 * 60 * 30) // run every hour
	// },
	// beforeDestroy() {
	// 	if (this.webAuthenticatorTimer)
	// 		clearInterval(this.webAuthenticatorTimer)
	// },
  methods: {
    ...mapActions("global", ["setContact", 'setRandomNumber']),
		authenticateWebToken() {
    const webToken = localStorage.getItem(
      `${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`
    );
		if (!webToken) {
			this.$router.push({ path: "/webauthenticator" });
		}
			return authService.verifyWebToken({ webToken })
				.then(response => {
          console.log('test response here', response)
					if (!response || !response.data || !response.data.token) {
						localStorage.removeItem(
            	`${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`,
          	);
						this.$router.push({ path: "/webauthenticator" });
					}
					return true
				})
				.catch(error => {
          console.log('test errror here', error)
					// localStorage.removeItem(
					// 	`${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`,
					// );
					// this.$router.push({ path: "/webauthenticator" });
				})
		},
    checkAuth() {
      // console.log('process.env.VUE_APP_STORAGE_PREFIX', process.env.VUE_APP_STORAGE_PREFIX)
      if (!localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`)) {
        this.authenticated = false;
      }
      this.polling = setTimeout(this.checkAuth, 5000);
    },
    joinSocketChannels() {
      const contact = JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      ))
      console.log('contact', contact)
      let locationCode = null 
      if (contact) {
        locationCode = contact.id
      }

      const deviceCode = 'contact'
      const deviceName = 'cellSims'

      console.log("location", locationCode, deviceCode, deviceName);

      if (locationCode && deviceCode) {
        this.$socket.emit("join-contact", {
          contact: locationCode,
          deviceCode,
          deviceName,
        });
      }

      //   if (this.$store.getters['global/location']) {
      //     this.$socket.emit('join-organization', {
      //       OrganizationId: this.$store.getters['global/location'].OrganizationId,
      //     });
      //   }
    },
  },
};
</script>

