<template>
  <span>
    <v-btn small class="rounded-lg" @click="dialog = !dialog">
      <span>Manage Funds</span>
    </v-btn>
    <!-- <a href="#" class="button glow-button">Glow Button</a>
    <a href="#" class="glow">Glow Button</a> -->
    <!-- <v-card width="200" @click="dialog = !dialog" :ripple="false" height="200" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/bill.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 font-weight-bold">
            Bill Payment
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card> -->

    <v-dialog v-model="dialog" width="400" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5">
          Add Funds
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>




        <v-card-text>

          <v-row class="ma-5" justify="center" v-if="cards.length">
            <v-col class="shrink">
              <input class="no-outline" ref="amountInput" v-model="amount" placeholder="$0" />
              <!-- <v-text-field @keyup.enter="$refs.fee.focus" ref="amount" reverse hide-details  outlined
                v-model="amount" @focus="$event.target.select()" label="Amount">
              </v-text-field> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="text-right" v-if="cards.length !== 0">
                <AddCard :contact="contact" @submit="cardSaved" />
              </div>

              <v-card color="primary" dark v-if="loading">
                <v-card-text>
                  Please stand by
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>

              <div v-else>
                <v-card outlined class="mt-1" v-if="cards.length">
                  <v-list dense style="max-height: 225px; overflow: auto;">
                    <template v-for="(card, index) in cards">
                      <!-- <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader> -->

                      <!-- <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider> -->

                      <v-list-item :key="index">
                        <v-list-item-avatar tile>
                          <v-img :src="cardImg(card.brand)"></v-img>
                          <!-- <v-img :src="item.avatar"></v-img> -->
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ card.brand }} ****{{ card.last4 }} </v-list-item-title>
                          <v-list-item-subtitle>Expires {{ card.exp_month }}/{{ card.exp_year }} <span
                              v-if="card.defaultCard">
                              <v-chip label text-color="blue darken-4" color="blue lighten-3" x-small>Defalut</v-chip>
                            </span></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-menu bottom left offset-y>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon>
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list class="menu-button">
                              <v-list-item @click="deleteCard(card)">
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                              <v-list-item v-if="!card.defaultCard" @click="setItDefault(card)">
                                <v-list-item-title>Set as default</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <!-- <v-btn icon @click="deleteCard(card)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn> -->
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                    </template>
                  </v-list>
                </v-card>

                <v-card v-else outlined class="mt-1">
                  <v-card-text>
                    <div class="text-center">No card on file</div>
                    <div class="text-center">
                      <AddCard :contact="contact" @submit="cardSaved" />
                    </div>
                  </v-card-text>
                </v-card>

              </div>


              <div v-if="amount" class="mt-4 text-center">Default card will get change for {{ amount | currency }}</div>

            </v-col>


          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn class="rounded-lg" large block :disabled="submitBtnStatus" :loading="buttonLoading" @click="submit"
                color="primary">
                Submit
              </v-btn>
            </v-col>

          </v-row>
        </v-card-text>


        <!-- <v-card-actions class="mb-5">
          <v-btn class="rounded-lg" block :disabled="phoneNumberCheck" :loading="loading" @click="submit" color="primary">
            Submit
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </span>

</template>

<script>
// import homeService from "@/modules/Wallet/service.js";
import EventBus from '@/event-bus.js';
import stripeService from '@/modules/Wallet/service.js'
import AddCard from '@/modules/Wallet/AddCard.vue'
import { mapGetters } from 'vuex';
// const { validationMixin } = require("vuelidate");
// const { required, minLength, requiredIf } = require("vuelidate/lib/validators");

export default {
  name: 'add-funds',
  props: ['contact'],
  data() {
    return {
      buttonLoading: false,
      customer: null,
      loading: true,
      cards: [],
      amount: null,
      fee: 0,
      dialog: false,
      description: null,
      limit: 1,
      orderBy: ['id', 'true'],
    }
  },
  components: {
    AddCard,
  },
  computed: {
    ...mapGetters('global', ['getContact']),
    submitBtnStatus() {
      let value = true
      if (this.amount && this.cards.length !== 0) {
        value = false
      }
      return value
    }
  },
  watch: {
    // cards(val) {
    //   console.log('card', this.cards)
    //   this.setDefaultCard()
    // },
    dialog(val) {
      if (val) {
        if (this.getContact.stripeId) {
          this.loadCard()
        } else {
          this.loading = false
        }
      }
    }
  },
  // "card_1M6nbZIP6Q0pVXmBc2dx4yZp"
  methods: {
    close() {
      this.dialog = false
      this.amount = null
    },
    setDefaultCard() {
      console.log('this.customer', this.customer, this.cards)
      if (this.customer && this.customer.default_source) {
        this.cards.map(card => {
          if (card.id === this.customer.default_source) {
            card.defaultCard = true
          } else {
            card.defaultCard = false
          }
        })
      }
      this.$forceUpdate();
    },
    setItDefault(card) {
      console.log('set as defalut', card)
      this.loading = true;
      let data = {
        customerId: this.customer.id,
        id: card.id,
      };
      console.log("setItDefault", data);
      return stripeService
        .defaultCard(data)
        .then(() => {
          this.loadCard();
          this.getCustomerInfo();
          this.loading = false;
          // 
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    deleteCard(card) {
      console.log('test delete card', card)
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {

        if (result.value) {
          this.loading = true;
          let data = {
            customerId: this.customer.id,
            id: card.id,
          };
          console.log("deleteCard", data);
          return stripeService
            .deleteCard(data)
            .then(() => {
              this.loadCard();
              this.getCustomerInfo();
              this.loading = false;
              // 
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      });
    },
    cardImg(item) {
      let card = 'https://posuserdata-production.s3.amazonaws.com/cards/credit-card.png'

      switch (item) {
        case 'Visa':
          card = 'https://posuserdata-production.s3.amazonaws.com/cards/visa.png'
          break;
        case 'MasterCard':
          card = 'https://posuserdata-production.s3.amazonaws.com/cards/mastercard.png'
          break;
        case 'American Express':
          card = 'https://posuserdata-production.s3.amazonaws.com/cards/american-express.png'
          break;
        case 'Discover':
          card = 'https://posuserdata-production.s3.amazonaws.com/cards/discover.png'
          break;

        default:
          card = 'https://posuserdata-production.s3.amazonaws.com/cards/cash.png'
          break;
      }
      return card;

    },
    getCustomerInfo() {
      return stripeService.getCustomer(this.contact.id)
        .then(result => {
          console.log('result customer', result)
          this.customer = result.data
          this.loading = false
          this.setDefaultCard()
        })
    },
    cardSaved() {
      this.loadCard()
      this.$refs.amountInput.focus()
    },
    loadCard() {
      return stripeService.getCustomerCard(this.contact.id)
        .then(result => {
          console.log('result', result)
          this.cards = result.data.data
          this.loading = false
          this.getCustomerInfo()
          setTimeout(() => {
            if (this.cards.length !== 0) {
              this.$refs.amountInput.focus()
            }
          }, 300);
        }).catch(error => {
          console.log('error on card', error)
          this.loading = false
        })
    },
    
    submit() {

      if (this.amount < 50) {
        this.$swal(
          "Add Funds",
          'Minimum value you can add in to wallet is $50.00',
          "error"
        );
        this.amount = 50
        return false
      }



      this.$swal({
        title: "Are you sure?",
        text: `You are adding ${this.$options.filters.currency(this.amount)} to your balance.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {

        if (result.value) {
          this.buttonLoading = true;
          const data = {
            id: this.getContact.id,
            amount: this.amount,
          }

          return stripeService.addFunds(data)
            .then(result => {
              console.log('result', result)
              this.buttonLoading = false
              EventBus.$emit('reload-contact-information')
              this.$toast.open({
                message: `${this.$options.filters.currency(this.amount)} was added to your account.`,
                dismissible: true,
                type: 'success',
                position: 'top-right',
              });
              this.amount = 0
              this.dialog = false
            }).catch(error => {
              this.buttonLoading = false
              this.$swal(
                "Add Funds",
                error.data.message,
                "error"
              );
              // this.$toast.open({
              //   message: error.data.message,
              //   dismissible: true,
              //   type: 'error',
              //   position: 'top-right',
              // });
            })
        }
      });




    },
  }
}
</script>

<style scoped>
.glow {
  line-height: 1.3;
  font-family: Lato, "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  transform-origin: center center;
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 0, 131);
  border: 1px solid transparent;
  transition: all 0.3s ease 0s, transform 0.5s ease 0s;
  box-shadow: rgb(255 0 131 / 50%) 0px 10px 40px -10px;
}

.button {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  background: rgb(145, 92, 182);
  padding: 15px 40px;
  border-radius: 4px;
  font-weight: normal;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}

.glow-button:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
}

input {
  /* border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #eee; */
  font-size: 72px;
  text-align: center;
  width: 220px;
  color: gray;
}

.no-outline:focus {
  outline: none;
  font-size: 72px;
}

/* CSS */
</style>